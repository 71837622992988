<template>
  <section>
      <div class="content-header">
        <h2>Free tool helps post-secondary students improve<br>mental well-being</h2>
      </div>
      <div class="content-wrapper">
        <p>Workplace Strategies, funded by Canada Life, has launched a free tool called <a href="https://www.workplacestrategiesformentalhealth.com/resources/post-secondary-student-resilience" target="_blank"><em>From Surviving to Thriving</em></a> to help improve post-secondary students' mental well-being. The tool was developed based on peer-reviewed academic research, and Queen's University completed a student pilot program testing it in 2019.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
